import Typography from "typography"
import Wordpress2016 from "typography-theme-wordpress-2016"

Wordpress2016.overrideThemeStyles = () => {
  return {
    "a.gatsby-resp-image-link": {
      boxShadow: `none`,
    },
    "h1,h2,h3": {
      fontFamily: '"Noto Sans JP", "Roboto", "sans-serif"'
    }
  }
}

delete Wordpress2016.googleFonts

const typography = new Typography({
  ...Wordpress2016,
  baseFontSize: '18px',
  googleFonts: [
    {
      name: 'Noto+Sans+JP',
      styles: ['400']
    }
  ],
  bodyFontFamily: ['Noto Sans JP', 'Roboto', 'sans-serif']
})

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
